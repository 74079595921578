body {
  margin: 0 0;
}

.map-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: stretch;
}

.modal-header {
  display: flex;
  align-items: baseline;
}

.modal-body {
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.modal-signin {
  margin: 30px 0 5px 0;
  display: flex;
  justify-content: center;
}

.modal-buttons {
  display: flex;
  justify-content: center;
}

/* #react-map {
  width: inherit;
  height: inherit;
} */

.s-lessons-inner-card-container {
  width: 13em;
  max-width: 13em;
  margin: 10px;
  height: 34em;
  display: flex;
  flex-direction: column;
  
}

.cluster-marker {
  color: #fff;
  background: #f6d635;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker:hover {
  cursor: pointer;
}

.s-lessons-map-avatar:hover {
  cursor: pointer;
}

.s-lessons-inner-card-container > .s-lessons-avatar-container {
  display: flex;
  align-items: center;
  margin: 10px;
}

.s-lessons-avatar {
  margin: 0 5px;
}
/* body{
  background-image: url("https://miro.medium.com/max/15904/1*eOTbC4Z-_tUat7CgfKI1Rg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
} */
/* 
#container {
  margin: auto;
  text-align: center; 
  margin-top: 150px;
}

.l-header {
  margin-bottom: 5%;
  color: #2d60f8;
}

.btn {
  padding: 10px;
  margin-bottom: 2%;
  padding-right: 15%;
  padding-left: 15%;
  border-radius: 5px;

} */