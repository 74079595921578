.pending-container {
    position: absolute;
    margin: 10px;
    top: 25%;
    left: 0;
    height: calc(75vh - 25%);
    width: 20%;
    border: solid 1px black;
    border-radius: 10px;
    opacity: 0.85;
    background-color: white;
    display: flex;
}

.pending-header {
    width: 100%;
    text-align: center;
    margin-top: 5px;
}