.menu-button {
    right: 50%;
    left: 50%;
    top: 0;
    height: 80px;
    position: absolute;
    overflow: hidden;
}

.menu-button:hover {
    cursor: pointer;
}

.menu-item {
    text-align: center;
}

/* .logo {
    position: absolute;    
    height: 80px;
    width: 80px;
    margin: 5px;
} */

.tooltiptext {
    visibility: hidden;
}